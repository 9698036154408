import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = {
    a: "a",
    code: "code",
    h2: "h2",
    h3: "h3",
    p: "p",
    pre: "pre",
    ..._provideComponents(),
    ...props.components
  }, {ItemBox, RarityBoxIcon, WeaponTypeIcon} = _components;
  if (!ItemBox) _missingMdxReference("ItemBox", true);
  if (!RarityBoxIcon) _missingMdxReference("RarityBoxIcon", true);
  if (!RarityBoxIcon.EPIC) _missingMdxReference("RarityBoxIcon.EPIC", true);
  if (!RarityBoxIcon.EXOTIC) _missingMdxReference("RarityBoxIcon.EXOTIC", true);
  if (!RarityBoxIcon.LEGENDARY) _missingMdxReference("RarityBoxIcon.LEGENDARY", true);
  if (!RarityBoxIcon.MYTHIC) _missingMdxReference("RarityBoxIcon.MYTHIC", true);
  if (!RarityBoxIcon.NEUTRAL) _missingMdxReference("RarityBoxIcon.NEUTRAL", true);
  if (!RarityBoxIcon.RARE) _missingMdxReference("RarityBoxIcon.RARE", true);
  if (!RarityBoxIcon.UNCOMMON) _missingMdxReference("RarityBoxIcon.UNCOMMON", true);
  if (!WeaponTypeIcon) _missingMdxReference("WeaponTypeIcon", true);
  if (!WeaponTypeIcon.ASSAULT_RIFLE) _missingMdxReference("WeaponTypeIcon.ASSAULT_RIFLE", true);
  if (!WeaponTypeIcon.GRENADE) _missingMdxReference("WeaponTypeIcon.GRENADE", true);
  if (!WeaponTypeIcon.ROCKET_LAUNCHER) _missingMdxReference("WeaponTypeIcon.ROCKET_LAUNCHER", true);
  if (!WeaponTypeIcon.SHOTGUN) _missingMdxReference("WeaponTypeIcon.SHOTGUN", true);
  if (!WeaponTypeIcon.SMG) _missingMdxReference("WeaponTypeIcon.SMG", true);
  if (!WeaponTypeIcon.SNIPER_RIFLE) _missingMdxReference("WeaponTypeIcon.SNIPER_RIFLE", true);
  if (!WeaponTypeIcon.TRAP) _missingMdxReference("WeaponTypeIcon.TRAP", true);
  if (!WeaponTypeIcon.VEHICLE) _missingMdxReference("WeaponTypeIcon.VEHICLE", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h2, {
      id: "common-components",
      children: _jsx(_components.a, {
        href: "#common-components",
        children: "Common Components"
      })
    }), "\n", _jsx(_components.h3, {
      id: "rarity-background",
      children: _jsx(_components.a, {
        href: "#rarity-background",
        children: "Rarity Background"
      })
    }), "\n", _jsx(_components.p, {
      children: "Can be used as component via a prop or referenced directly"
    }), "\n", _jsxs(_components.code, {
      preview: "flex gap-3",
      hideSource: true,
      className: "language-jsx",
      code: `  <RarityBoxIcon rarity="COMMON" />
  <RarityBoxIcon.UNCOMMON />
  <RarityBoxIcon.RARE />
  <RarityBoxIcon.EPIC />
  <RarityBoxIcon.LEGENDARY />
  <RarityBoxIcon.MYTHIC />
  <RarityBoxIcon.EXOTIC />
  <RarityBoxIcon.NEUTRAL />`,
      children: ["  ", _jsx(RarityBoxIcon, {
        rarity: "COMMON"
      }), _jsx(RarityBoxIcon.UNCOMMON, {}), _jsx(RarityBoxIcon.RARE, {}), _jsx(RarityBoxIcon.EPIC, {}), _jsx(RarityBoxIcon.LEGENDARY, {}), _jsx(RarityBoxIcon.MYTHIC, {}), _jsx(RarityBoxIcon.EXOTIC, {}), _jsx(RarityBoxIcon.NEUTRAL, {})]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-ts",
        children: "type Rarity =\n  | \"COMMON\"\n  | \"UNCOMMON\"\n  | \"RARE\"\n  | \"EPIC\"\n  | \"LEGENDARY\"\n  | \"MYTHIC\"\n  | \"EXOTIC\"\n  | \"NEUTRAL\";\n\ntype RarityBoxIconProps = {\n  rarity: Rarity;\n  width?: number;\n} & React.SVGProps<SVGSVGElement>;\n"
      })
    }), "\n", _jsx(_components.h3, {
      id: "weapon-type-icon",
      children: _jsx(_components.a, {
        href: "#weapon-type-icon",
        children: "Weapon Type Icon"
      })
    }), "\n", _jsxs(_components.code, {
      preview: "flex gap-3",
      hideSource: true,
      className: "language-jsx",
      code: `<WeaponTypeIcon type="PISTOL" width={40} />
<WeaponTypeIcon.SHOTGUN />
<WeaponTypeIcon.SMG />
<WeaponTypeIcon.ASSAULT_RIFLE />
<WeaponTypeIcon.SNIPER_RIFLE />
<WeaponTypeIcon.ROCKET_LAUNCHER />
<WeaponTypeIcon.GRENADE />
<WeaponTypeIcon.TRAP />
<WeaponTypeIcon.VEHICLE />`,
      children: [_jsx(WeaponTypeIcon, {
        type: "PISTOL",
        width: 40
      }), _jsx(WeaponTypeIcon.SHOTGUN, {}), _jsx(WeaponTypeIcon.SMG, {}), _jsx(WeaponTypeIcon.ASSAULT_RIFLE, {}), _jsx(WeaponTypeIcon.SNIPER_RIFLE, {}), _jsx(WeaponTypeIcon.ROCKET_LAUNCHER, {}), _jsx(WeaponTypeIcon.GRENADE, {}), _jsx(WeaponTypeIcon.TRAP, {}), _jsx(WeaponTypeIcon.VEHICLE, {})]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-ts",
        children: "type WeaponType =\n  | \"PISTOL\"\n  | \"SHOTGUN\"\n  | \"SMG\"\n  | \"ASSAULT_RIFLE\"\n  | \"SNIPER_RIFLE\"\n  | \"ROCKET_LAUNCHER\"\n  | \"GRENADE\"\n  | \"TRAP\"\n  | \"VEHICLE\";\n\ntype WeaponTypeIconProps = {\n  type: WeaponType;\n  width?: number;\n} & React.SVGProps<SVGSVGElement>;\n"
      })
    }), "\n", _jsx(_components.h3, {
      id: "itembox",
      children: _jsx(_components.a, {
        href: "#itembox",
        children: "ItemBox"
      })
    }), "\n", _jsxs(_components.code, {
      preview: "flex gap-3",
      hideSource: true,
      className: "language-jsx",
      code: `  <ItemBox id="anvil_rocket_launcher" name="Anvil Rocket Launcher" rarity="EPIC" />
  <ItemBox id="apple" name="Apple" rarity="NEUTRAL" />`,
      children: ["  ", _jsx(ItemBox, {
        id: "anvil_rocket_launcher",
        name: "Anvil Rocket Launcher",
        rarity: "EPIC"
      }), _jsx(ItemBox, {
        id: "apple",
        name: "Apple",
        rarity: "NEUTRAL"
      })]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-ts",
        children: "type ItemBoxProps = {\n  id: string;\n  name: string;\n  rarity: Rarity;\n  className?: string;\n  width?: number;\n}\n"
      })
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
export const meta = () => JSON.parse('{"title":[null,"Blitz Docs"],"description":"Docs for Blitz App"}');
